import TroubleShootingModal from './modal.vue';

export default {
  data() {
    return {
      showTroubleShootingModal: false,
    };
  },
  components: {
    TroubleShootingModal,
  },
};
