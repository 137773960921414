var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_vm._l((_vm.setup),function(values,index){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(values.visibility === undefined ? true : values.visibility),expression:"values.visibility === undefined ? true : values.visibility"}],key:index,class:values.class},[_c('label',{staticClass:" text-uppercase work-sans label-text"},[_vm._v(_vm._s(values.label))]),(values.type === 'dropdown')?_c('selectWrapper',{attrs:{"id":_vm.label,"value":_vm.payload[values.key],"options":values.options,"multiSelectProps":values.dropdownProps,"NoDataLabel":values.label},on:{"input":function($event){return _vm.updatePayload($event, values.key)}}}):_vm._e(),_c('div',{staticClass:"disabled_alt"},[(values.type === 'toggle')?_c('toggle',{on:{"change":function($event){return _vm.updatePayload($event, values.key)}}}):_vm._e()],1),(values.type === 'checkbox')?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex disabled_alt"},[_c('BaseCheckbox',{attrs:{"currentValue":_vm.payload.target.national},on:{"input":function($event){return _vm.updatePayload(
                { sdg: _vm.payload.target.sdg, national: $event },
                'target'
              )}}}),_c('p',{staticClass:"check-label ml-1"},[_vm._v("National")])],1),_c('div',{staticClass:"d-flex ml-3 disabled_alt"},[_c('BaseCheckbox',{attrs:{"currentValue":_vm.payload.target.sdg},on:{"input":function($event){return _vm.updatePayload(
                { sdg: $event, national: _vm.payload.target.national },
                'target'
              )}}}),_c('p',{staticClass:"check-label ml-1"},[_vm._v("SDG")])],1)]):_vm._e(),(values.type === 'visualization')?_c('div',{staticClass:"btn-group d-flex work-sans",attrs:{"role":"group"}},[_c('button',{staticClass:"btn btn-sm btn-outline-primary",class:[_vm.activeToggleButton === 'zonal_map' ? 'active' : ''],attrs:{"type":"button"},on:{"click":function($event){_vm.updatePayload('zonal_map', values.key),
              (_vm.activeToggleButton = 'zonal_map')}}},[_vm._v(" Zones Map "),_c('img',{attrs:{"src":require(("../svg/" + (_vm.activeToggleButton === 'zonal_map'
                  ? 'state_map_white'
                  : 'zonal_map') + ".svg")),"alt":"","srcset":""}})]),_c('button',{staticClass:"btn btn-sm btn-outline-primary",class:[_vm.activeToggleButton === 'state_map' ? 'active' : ''],attrs:{"type":"button"},on:{"click":function($event){_vm.updatePayload('state_map', values.key),
              (_vm.activeToggleButton = 'state_map')}}},[_vm._v(" State Map "),_c('img',{staticClass:"text-danger",attrs:{"src":require(("../svg/" + (_vm.activeToggleButton === 'state_map'
                  ? 'state_map_white'
                  : 'state_map') + ".svg")),"alt":"","srcset":""}})]),_c('button',{staticClass:"btn btn-sm btn-outline-primary",class:[_vm.activeToggleButton === 'line' ? 'active' : ''],attrs:{"type":"button"},on:{"click":function($event){_vm.updatePayload('line', values.key), (_vm.activeToggleButton = 'line')}}},[_vm._v(" Line "),_c('b-icon',{attrs:{"icon":"graph-up"}})],1),_c('button',{staticClass:"btn btn-sm btn-outline-primary",class:[_vm.activeToggleButton === 'column' ? 'active' : ''],attrs:{"type":"button"},on:{"click":function($event){_vm.updatePayload('column', values.key),
              (_vm.activeToggleButton = 'column')}}},[_vm._v(" Column "),_c('b-icon',{attrs:{"icon":"bar-chart-fill"}})],1)]):_vm._e()],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }