<template>
  <div
    class="
      align-items-center
      d-flex
      flex-column
      justify-content-center
      text-center
    "

  >
    <!-- <h5>NO SUB-NATIONAL LEVEL DATA</h5>
    <p>
      There is no sub-national data available for the indicator, data source and
      period you have selected. Try selecting another data source or period
    </p> -->
    <img src="@/assets/no-data/No_Sub-national_Data.svg" alt="no data" class="img-fluid" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
